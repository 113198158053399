import React from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { graphql } from 'gatsby';

import { getImagePreview } from 'utils';

import { Paragraphs, Layout, SEO } from 'components';

const LandingTemplate = ({
  data: {
    nodeLandingPage: {
      title,
      field_metatags,
      path: { alias },
      field_automatic_breadcrumbs,
      relationships: {
        field_image_header,
        field_header_paragraph,
        field_content,
        field_breadcrumbs,
      },
    },
  },
}) => {
  const paragraphHeader = field_header_paragraph.find(
    (item) => item?.internal?.type === 'paragraph__header_paragraph',
  );
  const imageOpenGraphPreview = field_image_header?.localFile.childImageSharp.fluid.src;
  const headerImage = paragraphHeader.field_image?.localFile?.publicURL;

  const imageUrl = getImagePreview(headerImage, imageOpenGraphPreview);

  return (
    <Layout
      headerData={field_header_paragraph}
      nodeTitle={title}
      isDefaultBreadcrumbs={field_automatic_breadcrumbs}
      customBreadcrumbs={field_breadcrumbs}
      type={paragraphHeader.field_description ? 'front landing-page' : 'front basic-page'}
    >
      <SEO
        title={field_metatags?.title || title}
        description={field_metatags?.description || ''}
        keywords={field_metatags?.keywords || ''}
        robots={field_metatags?.robots}
        currentUrl={alias}
        imageUrl={imageUrl}
      />
      <div>
        {field_content.map((item) => (
          <Paragraphs key={uid(item)} data={item} />
        ))}
      </div>
    </Layout>
  );
};

LandingTemplate.propTypes = {
  data: PropTypes.shape({
    nodeLandingPage: PropTypes.shape({
      title: PropTypes.string,
      field_metatags: PropTypes.object,
      path: PropTypes.shape({
        alias: PropTypes.string,
      }),
      field_automatic_breadcrumbs: PropTypes.bool,
      relationships: PropTypes.shape({
        field_image_header: PropTypes.object,
        field_header_paragraph: PropTypes.object,
        field_content: PropTypes.array,
        field_breadcrumbs: PropTypes.array,
      }),
    }),
  }),
};

export default LandingTemplate;

export const query = graphql`
  query($drupal_id: String!) {
    nodeLandingPage(drupal_id: { eq: $drupal_id }) {
      ...NodeLandingPage
    }
  }
`;
